import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 225, maxHeight: 225, grayscale: true) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const logoImageHeight = graphql`
  fragment logoImageHeight on File {
    childImageSharp {
      fluid(maxHeight: 60) {
        ...GatsbyImageSharpFluid_withWebp
        aspectRatio
        base64
        sizes
        src
        srcSet
      }
    }
  }
`

export const logoImageWidth = graphql`
  fragment logoImageWidth on File {
    childImageSharp {
      fluid(maxWidth: 160) {
        ...GatsbyImageSharpFluid_withWebp
        aspectRatio
        base64
        sizes
        src
        srcSet
      }
    }
  }
`

const About = (props) => {
  const imageQuery = useStaticQuery(graphql`
    query imageQuery {
      abhi: file(relativePath: { eq: "about/people/aramani.png" }) {
        ...squareImage
      }
      brian: file(relativePath: { eq: "about/people/bkannard.jpg" }) {
        ...squareImage
      }
      peter: file(relativePath: { eq: "about/people/psantamaria.jpg" }) {
        ...squareImage
      }
      brad: file(relativePath: { eq: "about/people/bliang.png" }) {
        ...squareImage
      }
      jbu: file(relativePath: { eq: "about/people/jbrockutne.png" }) {
        ...squareImage
      }
      wsgr: file(relativePath: { eq: "about/logos/wsgr.jpg" }) {
        ...logoImageWidth
      }
      biod: file(relativePath: { eq: "about/logos/biod.png" }) {
        ...logoImageWidth
      }
      shc: file(relativePath: { eq: "about/logos/shc.png" }) {
        ...logoImageWidth
      }
      hil: file(relativePath: { eq: "about/logos/hil.png" }) {
        ...logoImageWidth
      }
      nsf: file(relativePath: { eq: "about/logos/nsf.png" }) {
        ...logoImageHeight
      }
      sprk: file(relativePath: { eq: "about/logos/spark.png" }) {
        ...logoImageHeight
      }
    }
  `)
  return (
    <Layout>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        title="Flotherm - Company Information"
        meta={[
          {
            name: 'description',
            content:
              "Flotherm's company history, Leadership Team, Partners, and Supporting organizations",
          },
          {
            name: 'keywords',
            content:
              'Flotherm, Thermal Compression, Medical Devices, Stanford Hospitals & Clinics, Harvard Innovation Labs, Stanford Biodesign, Peter Santa Maria, Brian Kannard, Abhinav Ramani, Bradley Liang, John Brock-Utne',
          },
        ]}
      ></Helmet>

      <div id="main" className="wrapper style1">
        <div className="container">
          <header className="major">
            <h2>About the Company</h2>
            <p>Building Business with Patients at Heart</p>
          </header>
          <div className="row">
            <div className="col-12 col-12-medium imp-large">
              <section>
                <h3>History</h3>
                <p>
                  Founded out of Stanford University’s Biodesign program in
                  collaboration with leading global anesthesiologists, surgeons
                  and nurses, Flotherm was formed to address the needs for
                  better solutions in perioperative surgical safety. Our team is
                  composed of driven entrepreneurs with successful track records
                  developing and commercializing new medical technologies and
                  world-renowned clinical investigators and advisors.
                </p>
                <p>
                  Flotherm is angel-backed and located in Los Angeles, CA. The
                  Flotherm TCD is not approved for commercial sale anywhere
                  worldwide
                </p>
              </section>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-12-medium imp-medium">
              <section id="content">
                <div className="row">
                  <div className="col-12 col-12-medium imp-medium">
                    <h3>Our Team</h3>
                  </div>
                  <div className="col-3 col-4-large col-12-medium imp-medium">
                    <span className="image fit profile nooverlay">
                      <span className="card">
                        <Img
                          fluid={imageQuery.brian.childImageSharp.fluid}
                          alt="Brian Kannard"
                        />
                      </span>
                      <span className="card">
                        <h4>Brian Kannard</h4>
                        <div className="title">CEO</div>
                        <p>
                          Medtronic Diabetes
                          <br />
                          Intuitive Surgical
                          <br />
                          Stanford (MBA), USC (BS BME)
                        </p>
                      </span>
                    </span>
                  </div>
                  <div className="col-3 col-4-large col-12-medium imp-medium">
                    <span className="image fit profile nooverlay">
                      <span className="card">
                        <Img
                          fluid={imageQuery.peter.childImageSharp.fluid}
                          alt="Peter Santa Maria"
                        />
                      </span>
                      <span className="card">
                        <h4>Peter Santa Maria, MD PhD</h4>
                        <div className="title">CSO</div>
                        <p>
                          Stanford Otolaryngology, Otoinnovation/SPARK
                          <br />
                          Auration Biotech (lic. Astellas)
                          <br />
                          UWA (MD PhD)
                        </p>
                      </span>
                    </span>
                  </div>
                  <div className="col-3 col-4-large col-12-medium imp-medium">
                    <span className="image fit profile nooverlay">
                      <span className="card">
                        <Img
                          fluid={imageQuery.abhi.childImageSharp.fluid}
                          alt="Abhinav Ramani"
                        />
                      </span>
                      <span className="card">
                        <h4>Abhinav Ramani</h4>
                        <div className="title">CMO COO</div>
                        <p>
                          Consure Medical, Stanford Biodesign
                          <br />
                          ApniCure, NeoTract (acq. Teleflex)
                          <br />
                          Harvard (MBA), Stanford (MS, BS ME)
                          <br />
                        </p>
                      </span>
                    </span>
                  </div>
                  <div className="col-3 col-4-large col-12-medium imp-medium">
                    <span className="image fit profile nooverlay">
                      <span className="card">
                        <Img
                          fluid={imageQuery.brad.childImageSharp.fluid}
                          alt="Bradley Liang"
                        />
                      </span>
                      <span className="card">
                        <h4>Bradley Liang</h4>
                        <div className="title">CTO</div>
                        <p>
                          Medtronic Diabetes, Medallia (IPO)
                          <br />
                          Advanced Neuro Navigation Systems
                          <br />
                          Michigan (MS, BS BME EE)
                        </p>
                      </span>
                    </span>
                  </div>
                  <div className="col-3 col-4-large col-12-medium imp-medium">
                    <span className="image fit profile nooverlay">
                      <span className="card">
                        <Img
                          fluid={imageQuery.jbu.childImageSharp.fluid}
                          alt="John Brock-Utne"
                        />
                      </span>
                      <span className="card">
                        <h4>John Brock-Utne, MD</h4>
                        <div className="title">Clinical Advisor</div>
                        <p>
                          Stanford Professor Emeritus of Anesthesiology,
                          Perioperative Medicine
                          <br />
                          Avacore (prev. Dynatherm)
                          <br />
                          CA Board of Anesthesiology
                        </p>
                      </span>
                    </span>
                  </div>
                </div>
              </section>
              <hr />
            </div>
            <div className="col-10 col-12-medium imp-medium">
              <section>
                <h3>Partners and Collaborators</h3>
              </section>
              <div className="row">
                <div className="col-12 col-12-medium imp-medium">
                  <p>
                    <span className="image fit logo">
                      <Img
                        fluid={imageQuery.nsf.childImageSharp.fluid}
                        imgStyle={{
                          width: 'auto',
                          height: 'auto',
                          left: '55px',
                          top: '5px',
                        }}
                        alt="National Science Foundation SBIR"
                      />
                    </span>
                    <span className="image fit logo">
                      <Img
                        fluid={imageQuery.biod.childImageSharp.fluid}
                        imgStyle={{
                          width: 'auto',
                          height: 'auto',
                          left: '5px',
                          top: '19.15px',
                        }}
                        alt="Stanford Biodesign"
                      />
                    </span>
                    <span className="image fit logo">
                      <Img
                        fluid={imageQuery.hil.childImageSharp.fluid}
                        imgStyle={{
                          width: 'auto',
                          height: 'auto',
                          left: '5px',
                          top: '8.75px',
                        }}
                        alt="Harvard Innovation Labs"
                      />
                    </span>
                    <span className="image fit logo">
                      <Img
                        fluid={imageQuery.shc.childImageSharp.fluid}
                        imgStyle={{
                          width: 'auto',
                          height: 'auto',
                          left: '5px',
                          top: '15.5px',
                        }}
                        alt="Stanford Hospitals & Clinics"
                      />
                    </span>
                    <span className="image fit logo">
                      <Img
                        fluid={imageQuery.wsgr.childImageSharp.fluid}
                        imgStyle={{
                          width: 'auto',
                          height: 'auto',
                          left: '5px',
                          top: '11.5px',
                        }}
                        alt="WSGR"
                      />
                    </span>
                    <span className="image fit logo">
                      <Img
                        fluid={imageQuery.sprk.childImageSharp.fluid}
                        imgStyle={{
                          width: 'auto',
                          height: 'auto',
                          left: '26.5px',
                          top: '5px',
                        }}
                        alt="SPARK"
                      />
                    </span>
                  </p>
                </div>
              </div>
              <div className="row">
                <section>
                  <h3>Support</h3>
                </section>
                <ul>
                  <li>
                    National Science Foundation Small Business Innovation
                    Research (NSF SBIR) Grant Award (2034065)
                  </li>
                  <li>HBS Venture Incubation Program</li>
                  <li>
                    Robert Howard Next Step Award in Medical Technology
                    Innovation
                  </li>
                  <li>
                    Stanford SPECTRUM - Innovation Accelerator Seed Grant Award
                  </li>
                  <li>Flinders University Medical Device Partnering Program</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
